<script>
import { Button, InputNumber, Select } from 'ant-design-vue'
import { getAction } from '@/command/netTool'
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => {}
    },
    tenantId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {},
      secondaryList: []
    }
  },
  methods: {
    sumbit() {
      this.$emit('success', this.form)
    },
    onSearch(val) {
      getAction(
        '/system/commodity/farmShopSubMerchant/list?subMerName=' + val + '&tenantId=' + this.tenantId,
        {},
        '/bpi'
      ).then(res => {
        let { data } = res
        this.secondaryList = data.map(e => {
          return {
            label: e.subMerName,
            value: e.subMerId,
            incomeType: e.splitAccountFlag
          }
        })
      })
    },
    onDelData(index, indexId) {
      let { list1, list2 } = this.form
      if (index === 1) {
        list1.splice(indexId, 1)
      }
      if (index === 2) {
        list2.splice(indexId, 1)
      }
    },
    onAddData(index) {
      let { list1, list2 } = this.form
      if (index === 1) {
        list1.push({
          rate: null,
          merNo: null,
          merName: null,
          rateType: '0',
          incomeType: '',
          tenantId: this.tenantId
        })
      }
      if (index === 2) {
        list2.push({
          rate: null,
          merNo: null,
          merName: null,
          rateType: '1',
          incomeType: '',
          tenantId: this.tenantId
        })
      }
    },
    renderTop() {
      let { rate } = this.form
      return (
        <div class="top-box">
          <div class="title-class">平台手续费</div>
          <div>
            <div style={{ margin: '0 0 10px 0' }}>租户提现手续费（%）</div>
            <InputNumber
              style={{ width: '100%' }}
              controls={false}
              value={rate}
              onChange={val => (this.form.rate = val)}
            />
          </div>
        </div>
      )
    },
    renderItem(e, i, index, str) {
      return (
        <div style={{ margin: '0 0 10px 0' }} class="list-box">
          <div class="list-child">
            <span>手续费（%）</span>
            <InputNumber style={{ width: '100%' }} value={e.rate} controls={false} onChange={val => (e.rate = val)} />
          </div>
          <div class="list-child">
            <span>分配至二级商户</span>
            <Select
              style={{ width: '100%' }}
              value={e.merNo}
              showSearch={true}
              allowClear={true}
              optionFilterProp="children"
              onSelect={(val, str) => {
                e.merNo = val
                e.incomeType = str.data.attrs['data-incomeType'] || ''
                e.merName = str.data.attrs['data-name'] || ''
              }}
            >
              {this.secondaryList.map((m, mi) => {
                return (
                  <Select.Option key={mi} data-name={m.label} data-incomeType={m.incomeType} value={m.value}>
                    {m.label}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
          {i + 1 === index && i !== 0 ? (
            <Button class="button-del" size="middle" ghost type="danger" onClick={() => this.onDelData(str, i)}>
              删除
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      )
    },
    renderMid() {
      let { list1 } = this.form
      return (
        <div class="top-box">
          <div class="title-class">平台手续费分配（优先级从上倒下,分配完后剩余设置无效）</div>
          {list1.map((e, i) => {
            return this.renderItem(e, i, list1.length, 1)
          })}
          <Button class="button-add" type="dashed" ghost size="middle" onClick={() => this.onAddData(1)}>
            + 新增
          </Button>
        </div>
      )
    },
    renderBtm() {
      let { list2 } = this.form
      return (
        <div class="top-box">
          <div class="title-class">店铺手续费分配（优先级从上倒下,分配完后剩余设置无效）</div>
          {list2.map((e, i) => {
            return this.renderItem(e, i, list2.length, 2)
          })}
          <Button class="button-add" type="dashed" ghost size="middle" onClick={() => this.onAddData(2)}>
            + 新增
          </Button>
        </div>
      )
    },
    renderBtm2() {
      let { subMerId } = this.form
      return (
        <div class="top-box">
          <div class="title-class">
            店铺资金二级商户（当店铺没有关联二级商户，店铺资金结算到此二级商户，谨慎配置！）
          </div>
          <div>
            <div style={{ margin: '0 0 10px 0' }}>店铺资金结算默认二级商户</div>
            <Select
              style={{ width: '100%' }}
              value={subMerId}
              showSearch={true}
              allowClear={true}
              optionFilterProp="children"
              onSelect={(val, str) => {
                this.form.subMerId = val
              }}
            >
              {this.secondaryList.map((m, mi) => {
                return (
                  <Select.Option key={mi} data-name={m.label} data-incomeType={m.incomeType} value={m.value}>
                    {m.label}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
        </div>
      )
    }
  },
  mounted() {
    this.onSearch('')
    let { id, rate, subMerId, farmTenantRates } = this.dataSource
    this.form = {
      id,
      rate,
      subMerId,
      list1: farmTenantRates.filter(f => f.rateType == '0'),
      list2: farmTenantRates.filter(f => f.rateType == '1')
    }
  },
  render() {
    return (
      <div id="box">
        {this.renderTop()}
        {this.renderMid()}
        {this.renderBtm()}
        {this.renderBtm2()}
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
#box {
  width: 100%;
  overflow: hidden;

  .padding-class {
    padding: 0 40px 0 20px;
    box-sizing: border-box;
  }

  .title-class {
    height: 48px;
    font-family: 'PingFang-SC-ExtraLight', 'PingFang SC ExtraLight', 'PingFang SC';
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #17223d;
    line-height: 48px;
    background-color: #f2f2f2;
    margin-left: -20px;
    padding-left: 20px;
    width: 120%;
    margin-bottom: 15px;
  }

  .top-box {
    .padding-class;
    width: 100%;
    margin-bottom: 25px;
  }

  .button-add {
    font-family: PingFang-SC-ExtraLight, 'PingFang SC ExtraLight', 'PingFang SC';
    font-weight: 400;
    color: rgb(81, 90, 110);
    width: 100%;
  }

  .button-del {
    font-family: PingFang-SC-ExtraLight, 'PingFang SC ExtraLight', 'PingFang SC';
    font-weight: 400;
    color: #ed4014;
  }

  .list-box {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    border-bottom: 1px solid #e8eaec;
    padding-bottom: 15px;

    .list-child {
      display: flex;
      flex-direction: column;
      width: 40%;
      margin-right: 20px;

      span {
        font-family: 'PingFang-SC-ExtraLight', 'PingFang SC ExtraLight', 'PingFang SC';
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #515a6e;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
