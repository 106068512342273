<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { getAction, postAction } from '@/command/netTool'
import PopupView from './PopupView.vue'

// 租户
export default {
  name: 'tenant',
  data() {
    return {
      ...api.command.getState(),
      popupTitleArr: [],
      popupTitleChildArr: [],
      popupTitleSunLevelArr: [],
      popupID: []
    }
  },
  mounted() {
    this.getDataInit()
    api.command.getList.call(
      this,
      {
        url: '/system/farmTenant/page',
        current: 1,
        paramsValue: {
          host: '/bpi'
        }
      },
      '/bpi'
    )
  },
  methods: {
    getDataInit() {
      ;[
        {
          value: '/getDictList?dictType=cashier_type'
        },
        {
          value: '/getDictList?dictType=gateway_type'
        },
        {
          value: '/getWxMchList'
        },
        {
          value: '/getAliMchList'
        },
        {
          value: '/getNhMchList'
        }
      ].forEach((e, i) => {
        fetch('/cpi' + e.value)
          .then(response => {
            return response.json()
          })
          .then(myJson => {
            if (i == 0) {
              this.popupTitleArr = myJson.data
            } else if (i == 1) {
              this.popupTitleChildArr = myJson.data
            } else {
              this.popupTitleSunLevelArr.push({
                name: {
                  2: 'wxPay',
                  3: 'aliPay',
                  4: 'nhPay'
                }[i],
                value: myJson.data
              })
            }
          })
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'tenantName',
          type: 'input'
        },
        {
          label: '',
          key: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      // let that = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-125',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'tenantName',
          title: '系统名称',
          type: 'lt-250'
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          width: 60,
          typeData: ({ records }) => {
            return [
              {
                name: '手续费',
                onClick: () => {
                  getAction('/system/farmTenant/getRateInfo/' + records.id, {}, '/bpi').then(res => {
                    this.poundage(res.data, records.tenantId)
                  })
                }
              },
              // {
              //   name: '支付管理',
              //   onClick: () => {
              //     fetch('/cpi/get?appCode=' + records.tenantId)
              //       .then(response => {
              //         return response.json()
              //       })
              //       .then(myJson => {
              //         let data = myJson.data

              //         //* 存储id
              //         data.forEach(e => {
              //           this.popupID.push({
              //             id: e.id,
              //             key: `${e.payProductTypeValue + '|' + e.gatewayCode}`
              //           })
              //         })

              //         //   console.log(this.popupID)

              //         //* 处理数据
              //         let form = {}
              //         data.forEach(e => {
              //           form[e.payProductTypeValue + '|' + e.gatewayCode] = e.mchId
              //         })
              //         //* 去除form里的undefined
              //         Object.keys(form).map(item => {
              //           if (!form[item]) {
              //             delete form[item]
              //           }
              //         })

              //         this.handlePopup(form, records.tenantId, records.tenantName)
              //       })
              //   }
              // },
              {
                name: '分配用户',
                onClick: () => {
                  this.$router.push('/tenantManage/assignusers?tenantId=' + records.tenantId)
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push('/tenantManage/tenantDetail?id=' + records.id)
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.delPostArr.call(this, {
                    url: '/system/farmTenant/delete',
                    params: {
                      ids: [records.id]
                    },
                    host: '/bpi'
                  })
              }
            ]
          }
        }
      ]
    },
    poundage(dataSource = {}, tenantId) {
      apiTool.showDrawer({
        title: '手续费',
        width: '660px',
        view: PopupView,
        viewProps: {
          dataSource,
          tenantId
        },
        bodyStyle: { padding: '0' },
        headerStyle: { display: 'none' },
        success: ({ data, setHidden }) => {
          let { id, rate, list1, list2, subMerId } = data
          let params = {
            id,
            rate,
            subMerId,
            rates: list1.concat(list2)
          }
          postAction('/system/farmTenant/saveRateInfo', params, '/bpi').then(res => {
            if (res.code == 200) {
              this.$message.success('编辑成功')
              setHidden()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.$router.push('/tenantManage/tenantDetail')
          }
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //         let ids = data.map(e => e.id)
        //         return api.command.delPostArr.call(this, {
        //           url: '/system/farmTenant/delete',
        //           params: {
        //             ids: [ids]
        //           },
        //           host: '/bpi'
        //         })
        //       }
        //     }
        //   ]
        // }
      ]
    },
    handlePopup(source, tenantId, tenantName) {
      apiTool.showDrawer({
        title: '支付管理',
        width: '700px',
        view: DrawerForm,
        viewProps: {
          data: formData => this.getDrawerForm(source, formData),
          form: source
        },
        success: ({ data, setHidden }) => {
          let data1 = Object.keys(data)
          let data2 = Object.values(data)
          let data3 = []
          data1.forEach((e, i) => {
            let dArr = e.split('|')
            let ddArr = this.popupTitleSunLevelArr.find(f => dArr[1] == f.name)['value']

            data3.push({
              appCode: tenantId,
              appName: tenantName,
              payProductTypeLable: (
                this.popupTitleArr.find(f => f.dictValue == dArr[0]) || {
                  dictLabel: ''
                }
              ).dictLabel,
              payProductTypeValue: dArr[0],
              gatewayCode: dArr[1],
              gatewayName: (
                this.popupTitleChildArr.find(f => f.dictValue == dArr[1]) || {
                  dictLabel: ''
                }
              ).dictLabel,
              mchId: data2[i],
              mchName:
                (
                  ddArr.find(fi => fi.id == data2[i]) || {
                    mchName: ''
                  }
                ).mchName ||
                (
                  ddArr.find(fi => fi.id == data2[i]) || {
                    serviceName: ''
                  }
                ).serviceName,
              id: (this.popupID.find(f => f.key == e) || { id: '' }).id
            })
          })

          fetch('/cpi/save', {
            method: 'POST',
            body: JSON.stringify(data3),
            headers: new Headers({
              'Content-Type': 'application/json'
            })
          })
            .then(response => {
              return response.json()
            })
            .then(myJson => {
              this.$message.success(myJson.message)
              setHidden()
            })
            .catch(e => {
              this.$message.error(e.message)
            })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '提交',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getDrawerForm(source, formData) {
      const form = this.popupTitleArr.map((e1, i1) => {
        const obj =
          {
            APP: this.popupTitleChildArr.filter(e => e.dictValue != 'nhPay'),
            WMP: this.popupTitleChildArr.filter(e => e.dictValue == 'wxPay')
          }[e1.dictValue] || this.popupTitleChildArr
        return {
          title: e1.treeNames,
          form: obj.map((e2, i2) => {
            return {
              name: e2.treeNames,
              type: 'radioButton',
              key: e1.dictValue + '|' + e2.dictValue,
              typeData: this.popupTitleSunLevelArr
                .find(el => e2.dictValue == el.name)
                ['value'].map(e3 => {
                  return {
                    name: e3.serviceName || e3.mchName,
                    value: e3.id
                  }
                })
            }
          })
        }
      })
      return form
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        host="/bpi"
      />
    )
  }
}
</script>

<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    border-radius: 15%;
    margin-right: 10px;
  }
}
</style>
